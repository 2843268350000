const projectData = [
    {
        title: "Community Management cum Listing System API",
        description: [
            "Developed GraphQL API for the endpoints related to creating and managing communities, events and corresponding roles.",
            "Used permission classes for security, Django signals for validation and coordinated testing which led to 92% code coverage.",
            "Worked as a agile team which followed a complete software engineering life cycle.",
        ],
        links: {
            GitHub : "https://github.com/arpitvaghela/EveRecon-api",
        },
    },
    {
        title: "Song Popularity Prediction Using Machine Learning",
        description: [
            "Used Spotify’s song, artist and year related metadata to predict the popularity of a song.",
            "Performed feature engineering, model selection and hyperparameter tuning. Trained various ensemble learning and boosting algorithms for classification.",
            "Improved the prediction results from 60% to 90% accuracy.",
        ],
        links: {
            GitHub : "https://github.com/yashrajkakkad/song-popularity-prediction",
        },
    },
    {
        title: "Resea Operating System (Open Source Contribution)",
        description: [
            "Mitigated heap fragmentations in ‘malloc’ by replacing first-fit approach with multiple bins.",
            "Working on implementing Google’s Address Sanitizer to detect memory errors such as overflow and use-after-free.",
        ],
        links: {
            GitHub : "https://github.com/nuta/resea",
        },
    },
    {
        title: "covidTweet - Tweet Analyzer for COVID-19",
        description: [
            "Designed a relational model for tweets related to COVID-19 pandemic fetched using Twitter API.",
            "Used PostgreSQL procedures and functions to perform spatial, temporal and basic sentiment analysis.",
            "Displayed maps, heatmaps, graphs etc. over a web application using Flask and LeafletJS.",
        ],
        links: {
            GitHub : "https://github.com/yashrajkakkad/covidTweet",
        },
    },
    // {
    //     title: "Movie Recommendation System Using Graph Theory",
    //     description: [
    //         "Recommends movies based on user’s favourites using a graph constructed using genre, cast, directors etc.",
    //         "Programmed two algorithms from a paper and compared the results. Validated the results and gained inferences.",
    //         "Deployed using Django with simple Bootstrap frontend"
    //     ],
    //     links: {
    //         GitHub: "https://github.com/yashrajkakkad/movie-recommendation",
    //     },
    // },
    // {
    //     title: "Music Identification Through Audio Fingerprinting",
    //     description: [
    //         "Attempted to replicate Shazam’s concept of music identification - identifying a song from a small recording.",
    //         "Implemented an algorithm based on Fast Fourier Transform for fingerprinting a database of songs.",
    //         "Used an efficient technique to quickly compare the fingerprints with the user recording for quick identification, achieving 99% accuracy for noiseless clips.",
    //     ],
    //     links: {
    //         GitHub: "https://github.com/yashrajkakkad/presto-chango",
    //     },
    // },
    // {
    //     title: "16-bit Microprocessor Without Interlocked Pipeline Stages",
    //     description: [
    //         "RISC-based 28 instructions MIPS described on Verilog HDL using Dataflow modelling of circuits.",
    //         "Dumped on an FPGA Artix 7. Verified using testbenches and loading assembly programs.",
    //         "Enhanced the architecture with a direct cache-mapping scheme, modified booth multiplication algorithm and IEEE-754 half-precision standard floating-point addition.",
    //     ],
    //     links: {
    //         GitHub: "https://github.com/PrayagS/MIPS_16bit",
    //     },
    // }
];

export default projectData;