import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import {NavButton, NavButtonMobile} from "./NavButton.js";
import DarkButton from './DarkButton.js';

// export default function Navbar() {
//     return (
//     // <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>
//     <div className="min-h-screen">
//     <div className="antialiased bg-gray-100 dark-mode:bg-gray-900">
//     <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
//         <div x-data="{ open: true }" className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
//         <div className="flex flex-row items-center justify-between p-4">
//             <a href="#" className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">Flowtrail UI</a>
//             <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline" @click="open = !open">
//             <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
//                 <path x-show="!open" fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clip-rule="evenodd"></path>
//                 <path x-show="open" fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
//             </svg>
//             </button>
//         </div>
//         <nav className="{'flex': open, 'hidden': !open}" className="flex-col flex-grow hidden pb-4 md:pb-0 md:flex md:justify-end md:flex-row">
//             <a className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Blog</a>
//             <a className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Portfolio</a>
//             <a className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">About</a>
//             <a className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Contact</a>
//             {/* <div @click.away="open = false" className="relative" x-data="{ open: true }">
//             <button @click="open = !open" className="flex flex-row text-gray-900 bg-gray-200 items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
//                 <span>More</span>
//                 <svg fill="currentColor" viewBox="0 0 20 20" :className="{'rotate-180': open, 'rotate-0': !open}" className="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
//             </button>
//             <div x-show="open" x-transition:enter="transition ease-out duration-100" x-transition:enter-start="transform opacity-0 scale-95" x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75" x-transition:leave-start="transform opacity-100 scale-100" x-transition:leave-end="transform opacity-0 scale-95" className="absolute right-0 w-full md:max-w-screen-sm md:w-screen mt-2 origin-top-right">
//                 <div className="px-2 pt-2 pb-4 bg-white rounded-md shadow-lg dark-mode:bg-gray-700">
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                     <a className="flex flex row items-start rounded-lg bg-transparent p-2 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">
//                     <div className="bg-teal-500 text-white rounded-lg p-3">
//                         <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="md:h-6 md:w-6 h-4 w-4"><path d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path></svg>
//                     </div>
//                     <div className="ml-3">
//                         <p className="font-semibold">Appearance</p>
//                         <p className="text-sm">Easy customization</p>
//                     </div>
//                     </a>

//                     <a className="flex flex row items-start rounded-lg bg-transparent p-2 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">
//                     <div className="bg-teal-500 text-white rounded-lg p-3">
//                         <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="md:h-6 md:w-6 h-4 w-4"><path d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>
//                     </div>
//                     <div className="ml-3">
//                         <p className="font-semibold">Comments</p>
//                         <p className="text-sm">Check your latest comments</p>
//                     </div>
//                     </a>

//                     <a className="flex flex row items-start rounded-lg bg-transparent p-2 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">
//                     <div className="bg-teal-500 text-white rounded-lg p-3">
//                         <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="md:h-6 md:w-6 h-4 w-4"><path d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path><path d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path></svg>
//                     </div>
//                     <div className="ml-3">
//                         <p className="font-semibold">Analytics</p>
//                         <p className="text-sm">Take a look at your statistics</p>
//                     </div>
//                     </a>
//                 </div>
//                 </div>
//             </div>
//             </div>     */}
//         </nav>
//         </div>
//     </div>
//     </div>
//     </div>
//     );
// }

// export default function Navbar() {
//     return (
//     <nav className="flex flex-col text-left justify-between py-4 px-6 bg-white shadow sm:items-baseline w-full">
//     <div className="mb-2 sm:mb-0">
//         <a href="/home" class="text-2xl no-underline text-grey-darkest hover:text-blue-dark">Yashraj Kakkad</a>
//     </div>
//     <div className="px-auto cursor-pointer md:hidden">
//         <svg className="w-6" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
//         </svg>        
//     </div>
//     <div className="block flex flex-col md:flex-row">
//         <a href="/one" class="text-lg no-underline text-grey-darkest hover:text-blue-dark ml-2 sm:w-full md:w-default">One</a>
//         <a href="/two" class="text-lg no-underline text-grey-darkest hover:text-blue-dark ml-2 sm:w-full md:w-default">Two</a>
//         <a href="/three" class="text-lg no-underline text-grey-darkest hover:text-blue-dark ml-2 sm:w-full md:w-default">Three</a>
//     </div>
//     </nav>   
//     );
// }

// function Nav() {
//   return (
//     <div>
//       <nav className="bg-white">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="flex items-center justify-between h-16">
//             <div className="flex items-center">
//               <div className="flex-shrink-0">
//               <p>Yashraj Kakkad</p>
//                 {/* <img
//                   className="h-8 w-8"
//                   src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
//                   alt="Workflow"
//                 /> */}
//               </div>
//               <div className="hidden md:block">
//                 <div className="ml-10 flex items-baseline space-x-4">
//                   <a
//                     href="#"
//                     className=" hover:bg-gray-700 text-black hover:text-white px-3 py-2 rounded-md text-sm font-medium"
//                   >
//                     Dashboard
//                   </a>

//                   <a
//                     href="#"
//                     className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
//                   >
//                     Team
//                   </a>

//                   <a
//                     href="#"
//                     className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
//                   >
//                     Projects
//                   </a>

//                   <a
//                     href="#"
//                     className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
//                   >
//                     Calendar
//                   </a>

//                   <a
//                     href="#"
//                     className="text-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
//                   >
//                     Reports
//                   </a>
//                 </div>
//               </div>
//             </div>
//             <div className="-mr-2 flex md:hidden">
//               <button
//                 type="button"
//                 className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
//                 aria-controls="mobile-menu"
//                 aria-expanded="false"
//               >
//                 <span className="sr-only">Open main menu</span>

//                 <svg
//                   className="block h-6 w-6"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                   aria-hidden="true"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     stroke-width="2"
//                     d="M4 6h16M4 12h16M4 18h16"
//                   />
//                 </svg>

//                 <svg
//                   className="hidden h-6 w-6"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                   aria-hidden="true"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     stroke-width="2"
//                     d="M6 18L18 6M6 6l12 12"
//                   />
//                 </svg>
//               </button>
//             </div>
//           </div>
//         </div>

//         <div className="md:hidden" id="mobile-menu">
//           <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
//             {/* <a
//               href="#"
//               className="hover:bg-gray-700 text-black hover:text-white block px-3 py-2 rounded-md text-base font-medium"
//             >
//               Dashboard
//             </a>

//             <a
//               href="#"
//               className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
//             >
//               Team
//             </a>

//             <a
//               href="#"
//               className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
//             >
//               Projects
//             </a>

//             <a
//               href="#"
//               className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
//             >
//               Calendar
//             </a>

//             <a
//               href="#"
//               className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
//             >
//               Reports
//             </a> */}
//           </div>
//         </div>
//       </nav>

//     </div>
//   );
// }

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    return (
    <div>
    <nav className="bg-white dark:bg-black">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
            <div className="flex-shrink-0">
                {/* Yashraj Kakkad */}
                <div>
                <DarkButton />
            </div>
                {/* <img
                className="h-8 w-8"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                alt="Workflow"
                /> */}
            </div>
            <div className="hidden md:block">
                <div className="ml-10 flex items-center flex-wrap space-x-4">
                <NavButton text="Experience" />
                <NavButton text="Projects" />
                <NavButton text="Skills" />
                <NavButton text="Achievements" />
                <NavButton text="Community Building" />
                <NavButton text="Talks" />
                <NavButton text="Contact" />

                {/* <a
                    href="#"
                    className="hover:bg-gray-700 text-black hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                    Dashboard
                </a> */}

                {/* <a
                    href="#"
                    className="hover:bg-gray-700 text-black hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                    Team
                </a>

                <a
                    href="#"
                    className="hover:bg-gray-700 text-black hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                    Projects
                </a>

                <a
                    href="#"
                    className="hover:bg-gray-700 text-black hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                    Calendar
                </a>

                <a
                    href="#"
                    className="hover:bg-gray-700 text-black hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                    Reports
                </a> */}
                </div>
            </div>
            </div>
            <div className="-mr-2 flex md:hidden">
            <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
            >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                    />
                </svg>
                ) : (
                <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
                )}
            </button>
            </div>
        </div>
        </div>

        <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        >
        {() => (
            <div className="md:hidden" id="mobile-menu">
            <div ref={React.createRef} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <NavButtonMobile text="Experience" />
                <NavButtonMobile text="Projects" />
                <NavButtonMobile text="Skills" />
                <NavButtonMobile text="Achievements" />
                <NavButtonMobile text="Community Building" />
                <NavButtonMobile text="Talks" />
                <NavButtonMobile text="Contact" />
                {/* <a
                href="#"
                className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                Dashboard
                </a>

                <a
                href="#"
                className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                Team
                </a>

                <a
                href="#"
                className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                Projects
                </a>

                <a
                href="#"
                className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                Calendar
                </a>

                <a
                href="#"
                className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                Reports
                </a> */}
            </div>
            </div>
        )}
        </Transition>
    </nav>
    </div>
    );
}
  
  

