export default function Project(props) {
    return (
        <div className="max-w-2xl mx-auto my-3 px-2">
        <h3 className="font-bold text-3xl dark:text-gray-100">{props.title}</h3> 
        <ul className="list-disc list-inside text-lg dark:text-white">
        {props.description.map((data, key) => {
        return (
            <li key={key}>
                {data}
            </li>
        );
        })} 
       </ul>
       <p className="text-right dark:text-gray-100"><a href={props.links.GitHub} target="_blank" rel="noreferrer" className="underline transform hover:no-underline">View on GitHub</a></p>
       </div>

    );

}