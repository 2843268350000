import './App.css';
import Achievements from './components/Achievements.js';
import Communities from './components/Communities.js';
import Contact from './components/Contact.js';
import Intro from './components/Intro.js';
import Experience from './components/Experience.js';
import Footer from './components/Footer.js';
import Heading from './components/Heading.js';
import Navbar from './components/Navbar.js';
import Project from './components/Project.js';
import Quote from './components/Quote.js';
import Skills from './components/Skills.js';
import Talks from './components/Talks.js';
import experienceData from './data/experience.js';
import projectData from './data/project.js';

function App() {
  return (
    <div className="bg-gray-50 dark:bg-black">
      <Navbar />
      <Intro />
      <Heading
        text="Experience"
      />
      {experienceData.map((data, key) => {
        return (
          <Experience
          logo={data.logo}          
          org={data.org}
          position={data.position}
          description={data.description}
          duration={data.duration}
          key={key}
          />
        );       
      })}
      {/* <Experience
        logo="ahduni.jpg"
        org="Ahmedabad University"
      /> */}
      <Heading
        text="Projects"
      />
      {projectData.map((data, key) => {
        return (
          <Project
          title={data.title}
          description={data.description}
          links={data.links}
          key={key}
          />
        );       
      })}
      <Heading
        text="Skills"
      />
      <Skills />
      <Heading
        text="Achievements"
      />
      <Achievements />
      <Heading
        text="Community Building"
      />
      <Communities />
      <Heading
        text="Talks"
      />
      <Talks />
      <Heading
        text="Here's a Quote"
      />
      <Quote />
      <Heading
        text="Contact"
      />
      <Contact />
      <Footer />
    </div>
      // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
