import ReactPlayer from 'react-player'

export default function Talks() {
    return(
        <div className="max-w-2xl mx-auto px-2 text-lg">
        <p className="dark:text-white">I love teaching others and sharing my story! I have been invited by various colleges and communities to talk about competitive programming, Django and internships.</p>
        <p className="dark:text-white py-4"> If you are a community leader who'd like to invite me over, please use the <a href="#Contact" className="underline transform hover:no-underline">contact form below</a>.</p>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            <div>
            <ReactPlayer
            url="https://youtu.be/NOuD3pSroPA"
            width="100%"
            height="100%"
            />
            </div>
            <div>
            <ReactPlayer
            url="https://youtu.be/Dq3pvyKIC1g"
            width="100%"
            height="100%"
            />        
            </div>
            <div>
            <ReactPlayer
            url="https://youtu.be/W6AUu-nXhTI"
            width="100%"
            height="100%"
            />        
            </div>
            <div>
            <ReactPlayer
            url="https://youtu.be/4gXNtvRVSYk"
            width="100%"
            height="100%"
            />
            </div>
            <div>
            <ReactPlayer
            url="https://youtu.be/TWi07Tbl230"
            width="100%"
            height="100%"
            />
            </div>
            <div>
            <ReactPlayer
            url="https://youtu.be/xNDV_GiIjVM"
            width="100%"
            height="100%"
            />
            </div>

        </div>
        </div>

    );
}