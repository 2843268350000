export default function Contact() {
    return(
        <form action="https://formsubmit.co/6873278904eafc7199762bb51651a25c" method="POST">
            <div className="grid grid-cols-1 gap-6 mx-auto max-w-2xl px-2">
                <label className="block">
                    <span className="text-gray-700 text-xl dark:text-gray-100">Name</span>
                    <input type="text" name="name" className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" placeholder="John Doe" required/>                   
                </label>
                <label className="block">
                    <span className="text-gray-700 text-xl dark:text-gray-100">Email</span>
                    <input type="text" name="email" className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" placeholder="john@doe.com" required/>                   
                </label>
                <label className="block">
                    <span className="text-gray-700 text-xl dark:text-gray-100">Message</span>
                    <textarea name="message" className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" rows="2" spellCheck="false" placeholder="Hi, I'd like to talk about..." required></textarea>                
                </label>
                <button type="submit" value="submit" className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 mx-auto rounded max-w-sm dark:bg-gray-100 dark:text-black dark:hover:bg-gray-300 ">Submit</button>
            </div>
        </form>
    );
}