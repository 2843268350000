export default function Skills() {
    return(
        <div className="max-w-2xl mx-auto px-2">
            {/* <h3 className="font-bold text-xl">Languages</h3>  */}
            <ul className="grid sm:grid-cols-1 md:grid-cols-2 dark:text-gray-100">
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">Python</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">Java</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">C</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">C++</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">Django</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">REST</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">GraphQL</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">MATLAB</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">Elastic Search</li>
                <li className="hover:bg-gray-100 dark:hover:bg-gray-700 py-2">PostgreSQL</li>

            </ul>
        </div>
    );
}