const experienceData = [
    {
        org: "Goldman Sachs",
        position: "Summer Analyst",
        logo: "gs.jpg",
        duration: "June 2021 - July 2021",
        description: [
            "Selected in the Secdb architecture team, Engineering division for a summer internship. (More information soon)",
        ]
    },
    {
        org: "Ahmedabad University",
        position: "Undergraduate Research Fellow",
        logo: "ahduni.jpg",
        duration: "February 2021 - June 2021",
        description: [
            "Working on a project titled \"Mathematical modelling and Applied Deep Learning for 5G-NR and Wi-Fi 6\" under Prof. Dhaval Patel.",
            "The project is funded by DST-UKIERI and the University of Washington, Seattle, USA.",
            "Analyzed and successfully reproduced papers of top journals such as IEEE Transactions on Wireless Communications successfully.",
        ]        
    },
    {
        org: "CuratePad",
        position: "Backend Developer",
        logo: "curatepad.jpeg",
        duration: "May 2020 - August 2020",
        description: [
            "Built a RESTful API and the backend for the content curation platform using Django and Django REST Framework.",
            "Wrote scalable, maintainable, DRY code with unit tests, documentation and logging.",
            "Created a browser extension.",
            "Deployed the application to an AWS EC2 instance with the help of Nginx and Docker.",
        ]
    },
    {
        org: "AUM Event and Promotion Pvt. Ltd.",
        position: "Web Developer",
        logo: "aum.jpeg",
        duration: "September 2019 - December 2019",
        description: [
            "Designed and developed a Django-based web application for a system which tracks an inventory of products across different locations.",
            "Created a simple front-end with the help of Bootstrap.",
        ]
    }
]

export default experienceData;