const quoteData = [
    {
        quote: "Happiness is a choice and a skill and you can dedicate yourself to learning that skill and making that choice.",
        person: "Naval Ravikant"
    },
    {
        quote: "Have no fear of perfection. You'll never reach it.",
        person: "Sailvador Dali",
    },
    {
        quote: "Positive thinking will let you do everything better than negative thinking will.",
        person: "Zig Ziglar",
    },
    {
        quote: "People rarely succeed unless they have fun in what they are doing.",
        person: "Dale Carnegie",        
    },
    {
        quote: "I trust that everything happens for a reason, even if we are not wise enough to see it.",
        person: "Oprah Winfrey",
    },
    {
        quote: "The goal is not to be perfect by the end, the goal is to be better today.",
        person: "Simon Sinek",
    },
    {
        quote: "Remember that the happiest people are not those getting more, but those giving more.",
        person: "H. Jackson Brown Jr.",
    },
];

export default quoteData;