import dsc from '../assets/images/communities/dsc.jpg';
import codechef from '../assets/images/communities/codechef.jpg';
import ieee from '../assets/images/communities/ieee.png';
import pclub from '../assets/images/communities/pclub.jpg';

export default function Communities() {
    return(
        <div className="max-w-2xl mx-auto px-2 text-lg">
        <p className="dark:text-white">I have been involved in organizing 40+ events as a part of several communities with a net footfall of 2500+ people. I believe in giving back whatever I have learnt from others and try to help students get knowledge and exposure. I have been lucky enough to have like-minded people as teammates.</p>
        <p className="mt-3 dark:text-white">I've held leadership positions in the following organizations:</p>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 max-w-lg mx-auto my-auto gap-4 items-center">
            <img src={dsc} alt="DSC Ahmedabad University" width="100%" className="transition duration-500 ease-in-out w-15 mx-auto sm:mx-7 md:mx-0 filter grayscale transform hover:grayscale-0 hover:-translate-y-1 hover:scale-110 w-3/4 md:w-auto" />
            <img src={codechef} alt="CodeChef Campus Chapter - SEAS" width="100%" className="transition duration-500 ease-in-out w-15 mx-auto my-2 filter grayscale transform hover:grayscale-0 hover:-translate-y-1 hover:scale-110 w-3/4" />
            <img src={pclub} alt="Programming Club, Ahmedabad University" width="100%" className="transition duration-500 ease-in-out w-15 mx-auto my-2 filter grayscale transform hover:grayscale-0 hover:-translate-y-1 hover:scale-110 w-3/4" />
            <img src={ieee} alt="IEEE Student Branch - Ahmedabad University" width="100%" className="transition duration-500 ease-in-out w-15 mx-auto my-2 filter grayscale transform hover:grayscale-0 hover:-translate-y-1 hover:scale-110 w-3/4" />
        </div>
        </div>
    );
}