function NavButton(props) {
    const textHref = "#" + props.text;
    return (
        <a
        href={textHref}
        className="hover:bg-gray-700 text-black hover:text-white px-3 py-2 rounded-md text-sm font-medium dark:text-gray-100"
    >   
        {props.text}
    </a>

    );

}

function NavButtonMobile(props) {
    const textHref = "#" + props.text;
    return (
        <a
        href={textHref}
        className="text-black hover:text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium dark:text-gray-100"
        >
        {props.text}
        </a>
    
    );

}

export {NavButton, NavButtonMobile };