import quoteData from '../data/quote.js';

export default function Quote() {
    const nQuotes = quoteData.length;
    const index = Math.floor(Math.random() * nQuotes);
    const quote = quoteData[index];
    return(
        <div className="max-w-2xl mx-auto px-2">
        <div className="text-3xl text-gray-500 text-left leading-tight h-3">“</div>
            <h3 className="italic text-center text-gray-500 text-lg dark:text-gray-300">{quote.quote}</h3>
        <div className="text-3xl text-gray-500 text-right leading-tight h-3 -mt-3">”</div>
        <p className="text-center text-gray-700 text-bold text-md dark:text-gray-100">{quote.person}</p>

        <p className="mt-3 dark:text-white text-lg">Refresh the page to see another one of my favourites!</p>
        </div>    
    );
}