import achievementData from '../data/achievement.js';

export default function Achievements() {
    return(
        <div className="max-w-2xl mx-auto px-2 text-lg">
            <ul className="list-disc list-inside dark:text-white">
            {achievementData.map((data, key) => {
            return (
                <li key={key}>
                    {data}
                </li>
            );
            })} 
        </ul>

        </div>    
    );
}