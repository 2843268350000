export default function Experience(props) {
    // const logo = require('./ahduni.jpg').default;  
    const logo = require('../assets/images/logos/' + props.logo).default;
    return (
        <div className="md:grid md:grid-cols-3 md:gap-8 max-w-2xl mx-auto my-2">    
            <div>
                <img src={logo} alt={props.org} className="transition duration-500 ease-in-out mx-auto my-2 filter grayscale transform hover:grayscale-0 hover:-translate-y-1 hover:scale-110 w-1/2 md:w-3/4"/>
            </div>
            <div className="md:col-span-2 px-2">
               <h3 className="font-bold text-xl md:text-3xl dark:text-gray-100">{props.org}</h3> 
               <h4 className="italic dark:text-gray-200 text-xl">{props.position}</h4>
               <h4 className="text-gray-500 text-lg">{props.duration}</h4>
               <ul className="list-disc list-inside dark:text-white text-lg">
                {props.description.map((data, key) => {
                return (
                    <li key={key}>
                        {data}
                    </li>
                );
                })} 
               </ul>
               {/* <div class="object-right-top">
                Hi there!
                </div> */}
            </div>
        </div>    
    );
}