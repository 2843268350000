export default function Intro() {
    return (
        <div className="mx-auto my-auto px-4 py-8">
            <h1 className="text-6xl md:text-8xl lg:text-8xl font-bold text-center mx-auto max-w-2xl my-4 dark:text-white">Hi 👋, I am Yashraj Kakkad!</h1>
            {/* <p className="mx-auto text-center max-w-md text-xl md:text-2xl">Third-year computer science undergrad</p> */}
            <p className="mx-auto max-w-2xl text-lg md:text-2xl dark:text-gray-100 py-5">I am a third-year computer science undergrad, from Ahmedabad University. I have spent my time in back-end development, competitive programming and research, among other things.</p>
            <p className="mx-auto max-w-2xl text-lg md:text-2xl dark:text-gray-100 py-5">I am fairly active on <a href="https://www.linkedin.com/in/yashrajk/" target="_blank" rel="noreferrer" className="underline transform hover:no-underline">LinkedIn</a>. My work is hosted on <a href="https://github.com/yashrajkakkad" target="_blank" rel="noreferrer" className="underline transform hover:no-underline">GitHub</a>.</p>
            <p className="mx-auto max-w-2xl text-lg md:text-2xl dark:text-gray-100 py-5">You can <a href="https://drive.google.com/file/d/1wmVce2fcuXFbj_0CUIla5epeQ78CO_dp/view?usp=sharing" target="_blank" rel="noreferrer" className="underline transform hover:no-underline">download a PDF of my resume.</a></p>
        </div>
    );
}
